"use client";

import React, { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { TrashIcon, UploadIcon, CircleCheckBig } from "lucide-react";
import { useSessionService } from "@/hooks/useSession";
// import DateRangePickerSingle from './DateRangePickerSingle'
// const MEETING_NAME_MAX_LENGTH = 30

export default function UploadMeetingForm({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [files, setFiles] = useState<File[]>([]);
  const [language, setLanguage] = useState("pl");
  const [participants, setParticipants] = useState("auto");
  const [meetingName, setMeetingName] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [date, setDate] = useState<Date | undefined>();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sent, setSent] = useState(false);
  const { startSession, endSession, deleteSession, session, showToast } =
    useSessionService();
  const t = function (key: string) {
    return key;
  };

  useEffect(() => {
    if (open) {
      setSent(false);
    }
  }, [open]);

  useEffect(() => {
    const uploadFile = async () => {
      if (session) {
        try {
          setUploadingFile(true);
          let uploadResponseError = false;
          const blob = new Blob([files[0]]);
          const formData = new FormData();
          Object.entries(session.s3_url.fields).forEach(([key, value]) => {
            formData.append(key, value as string);
          });
          formData.append("file", blob);
          try {
            const response = await fetch(session.s3_url.url, {
              method: "POST",
              body: formData,
            });

            if (!response.ok) {
              uploadResponseError = true;
              showToast(t("uploadError"), t("uploadErrorDesc"));
            }
          } catch (error) {
            uploadResponseError = true;
            await deleteSession();

            showToast(t("uploadError"), t("uploadErrorDesc"));
          }

          const delay = uploadResponseError ? 1000 : 5000;

          if (uploadResponseError) {
            setTimeout(() => {
              resetUploadMeetingForm();
            }, delay);
            return;
          }
          await endSession(language, participants, meetingName);

          setUploadingFile(false);
        } catch (e) {
          console.log(e);
        }
      }
    };

    uploadFile();
  }, [session]);

  // const handleFilterByDate = (date: Date | undefined) => {
  //   if (date) {
  //     setDate(date);
  //   }
  // };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fileList = Array.from(event.target.files!);
    setFiles([...files, ...fileList]);
  };

  const handleFileDelete = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);

    const fileInput = document.getElementById(
      "file-upload"
    ) as HTMLInputElement;
    if (fileInput) fileInput.value = "";
  };

  const resetUploadMeetingForm = () => {
    setLanguage("pl");
    setParticipants("auto");
    setMeetingName("");
    setFiles([]);
    // setDate(undefined);
  };

  const handleUploadMeeting = async () => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      await startSession();
      // handleClose();
    } finally {
      setIsSubmitting(false);
      setSent(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    resetUploadMeetingForm();
  };

  const languages = [
    { name: t("polish"), value: "pl" },
    { name: t("auto"), value: "auto" },
    { name: t("english"), value: "en" },
    { name: t("german"), value: "de" },
    { name: t("swissGerman"), value: "gs" },
  ];

  const participantsOptions = [
    { name: "Auto", value: "auto" },
    { name: "1", value: "1" },
    { name: "2", value: "2" },
    { name: "3", value: "3" },
    { name: "4", value: "4" },
    { name: "5", value: "5" },
    { name: "6", value: "6" },
    { name: "7", value: "7" },
    { name: "8", value: "8" },
  ];

  return (
    <Sheet open={open} onOpenChange={handleClose}>
      <SheetContent>
        {!sent ? (
          <>
            <SheetHeader className="mb-4">
              <SheetTitle className="flex items-center gap-2 text-xl">
                Upload meeting
              </SheetTitle>
            </SheetHeader>
            <div
              className="overflow-y-auto max-h-[80vh] px-2 sm:max-h-[80vh] [&::-webkit-scrollbar]:w-2 
                       [&::-webkit-scrollbar-track]:bg-gray-100 
                       [&::-webkit-scrollbar-thumb]:bg-gray-300 
                       dark:[&::-webkit-scrollbar-track]:bg-neutral-700 
                       dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div>
                <div className="mb-4">
                  <Label htmlFor="language" className="mb-2">
                    Meeting language
                  </Label>
                  <Select
                    onValueChange={(e) => setLanguage(e)}
                    defaultValue={language}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Language" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {languages.map(({ value, name }) => (
                          <SelectItem key={value} value={value}>
                            {name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                <div className="mb-4">
                  <Label className="mb-2" htmlFor="participants">
                    No. of participants
                  </Label>
                  <Select
                    onValueChange={(e) => setParticipants(e)}
                    defaultValue={participants}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Auto" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {participantsOptions.map(({ value, name }) => (
                          <SelectItem key={value} value={value}>
                            {name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                <Label className="mb-2" htmlFor="files">
                  File to upload
                </Label>
                <div
                  className="relative flex h-[180px] w-full cursor-pointer flex-col 
                           items-center justify-center rounded-lg border-2 border-dashed 
                           bg-gray-50 hover:bg-gray-100 dark:border-gray-600 
                           dark:bg-gray-700 dark:hover:border-gray-500 
                           dark:hover:bg-gray-800 hover:dark:bg-gray-600 mb-4"
                >
                  <Input
                    className="absolute inset-0 h-full w-full cursor-pointer opacity-0"
                    id="file-upload"
                    placeholder={t("fileToUploadPlaceholder")}
                    type="file"
                    onChange={handleFileChange}
                    multiple
                    disabled={files.length > 0}
                  />
                  <div className="flex flex-col items-center justify-center pb-6 pt-5">
                    <UploadIcon className="mb-3 h-10 w-10 text-gray-400" />
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      up to 1 GB
                    </p>
                  </div>
                </div>
                {files.length > 0 && (
                  <div className="mt-2 mb-4 space-y-2">
                    {files.map((file, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between"
                      >
                        <span className="font-medium truncate max-w-[200px] overflow-hidden">
                          {file.name}
                        </span>
                        <Button
                          className="text-red-500 hover:text-red-600"
                          size="sm"
                          variant="outline"
                          onClick={() => handleFileDelete(index)}
                          disabled={uploadingFile}
                        >
                          <TrashIcon className="mr-1 h-4 w-4" />
                          Delete
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <SheetFooter>
                <Button
                  variant="special"
                  className="w-full mt-4"
                  disabled={files.length === 0 || uploadingFile || isSubmitting}
                  onClick={handleUploadMeeting}
                >
                  {uploadingFile ? "Uploading..." : "Upload meeting"}
                </Button>
              </SheetFooter>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center gap-4 text-center h-[80%]">
            <CircleCheckBig size={50} color="#059669" />
            <p className="text-2xl">Your file has been uploaded successfully.</p>
            <p className="text-lg mb-4">Now we need a while to process your file. It should be ready in a few minutes.</p>

            <Button variant="special" onClick={handleClose}>
              Close
            </Button>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
}
