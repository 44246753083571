import { useEffect, useState } from 'react';
import Modal from './Modal';

const AddToHomeScreenPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const lastPrompt = localStorage.getItem('lastPromptDate');
    const today = new Date().toISOString().split('T')[0];
    if (lastPrompt === today) return;

    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setOpen(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const handleInstall = async () => {
    if (deferredPrompt && 'prompt' in deferredPrompt) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const event = deferredPrompt as any;
      await event.prompt();
      const choiceResult = await event.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted');
      } else {
        console.log('User dismissed');
      }
      setDeferredPrompt(null);
      setOpen(false);
      localStorage.setItem('lastPromptDate', new Date().toISOString().split('T')[0]);
    }
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('lastPromptDate', new Date().toISOString().split('T')[0]);
  };

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      title="Add to Home Screen"
      action="addToHomeScreen"
      primaryButton="Add"
      primaryButtonAction={handleInstall}
      secondaryButton="Close"
      secondaryButtonVisible
      secondaryButtonAction={handleClose}
    >
      Add this app to your home screen for quick access!
      <br />
      Would you like to add it now?
    </Modal>
  );
};

export default AddToHomeScreenPrompt;
