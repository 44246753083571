import React, { useEffect } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Login from "./pages/Login";
import Recorder from "./pages/Recorder";
import { setupAxiosInterceptors } from "./services/axiosConfig";
import { useInterval } from "./hooks/useInterval";
import { useWakeLock } from "react-screen-wake-lock";
import Spinner from "./components/Spinner";
import AddToHomeScreenPrompt from "./components/AddToHomeScreenPrompt";

const App: React.FC = () => {
  const auth0 = useAuth0();
  const { isSupported, request, release } = useWakeLock();

  useEffect(() => {
    const lockOrientation = async () => {
      // @ts-expect-error 
      if (screen.orientation && screen.orientation.lock) {
        try {
          // @ts-expect-error
          await screen.orientation.lock("portrait");
          console.log("Screen orientation locked to portrait");
        } catch (error) {
          console.error("Failed to lock screen orientation:", error);
        }
      } else {
        console.warn("Screen orientation lock is not supported on this device");
      }
    };

    lockOrientation();

    return () => {
      if (screen.orientation && screen.orientation.unlock) {
        screen.orientation.unlock();
        console.log("Screen orientation unlocked");
      }
    };
  }, []);

  useEffect(() => {
    setupAxiosInterceptors(auth0);

    if (isSupported) {
      request();
    } else {
      console.error("Wake Lock API is not supported");
    }

    const handleVisibilityChange = () => {
      if (isSupported && document.visibilityState === "visible") {
        request();
      } else {
        release();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isSupported, request, release]);

  useInterval(async () => {
    if (!auth0.isAuthenticated) {
      return;
    }

    try {
      await auth0.getAccessTokenSilently();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);

      if (error.error === "login_required") {
        window.localStorage.setItem("notifyUserAutoLogout", "true");
        await auth0.logout({
          logoutParams: { returnTo: window.location.origin },
        });
      }
    }
  }, 5000);

  if (auth0.isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              auth0.isAuthenticated ? (
                <Navigate to="/recorder" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/recorder"
            element={
              auth0.isAuthenticated ? <Recorder /> : <Navigate to="/login" />
            }
          />
          <Route path="*" element={<Navigate to="/recorder" />} />
        </Routes>
      </BrowserRouter>
      <AddToHomeScreenPrompt />
    </>
  );
};

export default App;
