import { useState } from "react";
import axios from "axios";
import { SessionResponse } from "@/models/session";
import { dateToLocalTime, formatDate } from "@/lib/utils";
import { PREVIOUS_SESSION } from "@/contants";

const API_START_SESSION = "/api/v1/recorder/session/start";
const API_END_SESSION = "/api/v1/recorder/session/end";

interface EndSessionBody {
  language: string;
  participants: string;
  name?: string;
  actions?: string[] | null;
}

export const useSessionService = () => {
  const [session, setSession] = useState<SessionResponse | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingError, setRecordingError] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    title: "",
    description: "",
  });

  const showToast = (title: string, description: string) => {
    setToast({ open: true, title, description });
  };

  const resetSession = () => {
    setSession(null);
    setIsRecording(false);
    setRecordingError(false);
    localStorage.removeItem(PREVIOUS_SESSION);
  };

  const startSession = async () => {
    try {
      const recordTime = new Date();
      const response = await axios.post(API_START_SESSION, {
        record_time: dateToLocalTime(recordTime),
        name: formatDate(recordTime),
      });
      const data = response.data;

      if (data) {
        setSession(data);
        localStorage.setItem(PREVIOUS_SESSION, JSON.stringify(data));
        setIsRecording(true);
      }
    } catch (error) {
      console.error("Error starting session:", error);
      showToast(
        "Error",
        "Failed to start recording session. Please try again."
      );
      resetSession();
      throw new Error("Failed to start the session");
    }
  };

  const endSession = async (
    language: string = "auto",
    participants: string = "auto",
    name: string = formatDate(new Date())
  ) => {
    if (!session) return;

    if (!navigator.onLine) {
      showToast(
        "Error",
        "No internet connection. Please reconnect and try again."
      );
      setRecordingError(true);
      throw new Error("no internet connection");
    } else {
      setRecordingError(false);
    }

    try {
      const uid = session.uuid;
      const finalName = name && name.trim() ? name : formatDate(new Date());
      const body: EndSessionBody = { language, participants, name: finalName, actions: ["ypow"] };

      const response = await axios.post(`${API_END_SESSION}/${uid}`, body);

      if (response.status === 200) {
        setIsRecording(false);
        setRecordingError(false);
      }
    } catch (error) {
      console.error("Error ending session:", error);
      showToast("Error", "Failed to end recording session.");
      setRecordingError(true);
      throw new Error("failed to end session");
    }
  };

  const deleteSession = async () => {
    if (!session) return;

    try {
      const uid = session.uuid;
      const response = await axios.delete(
        `/api/v1/recorder/session/${uid}/cancel`
      );

      if (response.status === 200) {
        setIsRecording(false);
        localStorage.removeItem(PREVIOUS_SESSION);
      }
    } catch (error) {
      console.error("Error deleting session:", error);
      showToast("Error", "Failed to delete session.");
      throw new Error("failed to delete session");
    }
  };

  return {
    startSession,
    endSession,
    deleteSession,
    session,
    setSession,
    isRecording,
    setIsRecording,
    recordingError,
    setRecordingError,
    toast,
    setToast,
    showToast,
    resetSession,
  };
};
