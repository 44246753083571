import React from "react";
import ReactDOM from "react-dom";
import {
  Dialog,
  DialogContent,
  // DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../@/components/ui/dialog";
import { Button } from "@/components/ui/button";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  action: string;
  primaryButton: string;
  secondaryButton?: string;
  children: React.ReactNode;
  primaryButtonAction: () => void;
  secondaryButtonVisible: boolean;
  secondaryButtonAction?: () => void;
  closeButtonVisible?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  action,
  primaryButton,
  primaryButtonAction,
  secondaryButton,
  secondaryButtonVisible,
  secondaryButtonAction,
  closeButtonVisible = false,
}) => {
  if (!isOpen) return null;

  const buttonColor = () => {
    if (action === "delete") {
      return "bg-red-600";
    } else if (action === "save") {
      return "bg-green-600";
    }
  };

  return ReactDOM.createPortal(
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        closeButton={closeButtonVisible}
        onEscapeKeyDown={(e) => e.preventDefault()}
        onInteractOutside={(e) => e.preventDefault()}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div>{children}</div>
        <DialogFooter>
          {secondaryButtonVisible && (
            <Button onClick={secondaryButtonAction} size="lg" variant="outline">
              {secondaryButton}
            </Button>
          )}
          <Button
            onClick={primaryButtonAction}
            className={buttonColor()}
            variant={action === "delete" ? "destructive" : "default"}
            size="lg"
          >
            {primaryButton}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>,
    document.body
  );
};

export default Modal;
